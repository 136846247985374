import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { Auth, Hub } from 'aws-amplify';
import Form from './Form';
import UserContext from './UserContext';
import Utilities from '../src/utility/utilities';
import './Profile.css';

const Profile = (props) => {

  const userContext = useContext(UserContext);
  console.log('userContext as seen by Profile Page: ', userContext);

  const [user, setUser] = useState(null);
  
  useEffect(() => {
    checkUser();
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        setUser(null)
      }
    });
  }, []);

  const goToCollections = () => {
    checkUser();
    if (user.username !== undefined) Utilities.sendPayLoad(props, '/collections', user);
  }

  async function checkUser() {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes };
      setUser(userInfo);
      
    } catch (err) { console.log('error: ', err) }
  }

  function signOut() {
    Auth.signOut()
      .catch(err => console.log('error signing out: ', err))
  }
  
  if (user) {
    if (userContext.username === undefined) userContext.username = user.username;
    return (
      <div className="profile-container">
        <h1>Profile</h1>
        <h2>Username: {user.username}</h2>
        <h3>Email: {user.email}</h3>
        <h4>Phone: {user.phone_number}</h4>
        <hr></hr>
        <h2>Wallet Address: {userContext.user_wallet_address}</h2>
        <Button onClick={signOut}>Sign Out</Button>
        <br></br>
        <Button onClick={goToCollections}>Proceed</Button>
      </div>
    );
  }
  return <Form setUser={setUser} />
}

export default Profile
